import React, { useEffect } from 'react';
import pic3 from './assets/Studio-Project (1).avif';
import pic4 from './assets/Studio-Project (4).avif';
import pic5 from './assets/Studio-Project (5).avif';
import pic6 from './assets/Studio-Project (6).avif';
import pic7 from './assets/Studio-Project (7).avif';
import pic11 from './assets/Studio-Project (16).avif';
import pic12 from './assets/Studio-Project (12).avif';
import pic22 from './assets/Studio-Project (2).avif';
import pic14 from './assets/Studio-Project (17).avif';
import pic15 from './assets/Studio-Project (3).avif';
import pic16 from './assets/Studio-Project (18).avif';
import logo from './compuservlogo.jpg';
import './App.css';

function App() {
    return (
        <div className="App">
            <div className="background-container">
                <section className="content">
                    <section className="horizontal-section">
                        <div className="horizontal-content">
                            <h2>CompuServ</h2>
                            <p>Gets The Job Done.</p>
                        </div>
                    </section>
                    <section className="experience-section">
                        <div className="experience-content">
                            <h2>14+ Years of Expertise You Can Trust</h2>
                            <p>
                                Serving the Imperial Valley with dependable, easy-to-understand solutions.
                                From home setups to business IT challenges, we're here to make technology
                                simple and reliable for everyone.
                            </p>
                        </div>
                    </section>
                </section>
            </div>

            <section className="half-screen-section">
                <div className="half-screen-content">
                    <h3>Our Approach</h3>
                    <p>
                        We deliver strategic IT consulting with tailored solutions to meet your unique needs.
                        From computer sales and digital surveillance installation to network security,
                        VoIP systems, and programming, our experts provide innovative and practical
                        services that enhance efficiency and drive success.
                    </p>
                </div>
                <div className="half-screen-image">
                    <img src={pic16} alt="Description of image" loading="lazy" />
                </div>
            </section>

            <section className="image-text-section">
                <div className="image-container">
                    <img src={pic4} alt="General repair" loading="lazy" />
                </div>
                <div className="text-container">
                    <h2>Computer Repair</h2>
                    <p>
                        We provide expert computer repair services to keep your devices running smoothly.
                        From virus removal and hardware upgrades to troubleshooting and diagnostics.
                        Whether it's a home PC or a business system, you can trust us to deliver reliable,
                        tailored solutions.
                    </p>
                </div>
            </section>

            <section className="image-text-section">
                <div className="image-container">
                    <img src={pic5} alt="Server pic" loading="lazy" />
                </div>
                <div className="text-container">
                    <h2>Server Installation and Support</h2>
                    <p>
                        Our server installation and support services are designed to optimize your business
                        operations with reliable and scalable solutions. From initial setup to ongoing
                        maintenance, we ensure your servers perform seamlessly and securely. Count on us for
                        expert support that keeps your systems running smoothly, no matter the challenge.
                    </p>
                </div>
            </section>

            <section className="servicesunder">
                <div className="servicesunder-content">
                    <h2>Website Hosting</h2>
                    <p>
                        Our website hosting service ensures your online presence is fast, secure,
                        and always available. We offer reliable hosting solutions with high uptime,
                        scalable plans, and excellent customer support, so your website runs smoothly
                        at all times. Whether you're just starting out or managing a large-scale website,
                        we have the perfect hosting solution for you.
                    </p>
                </div>
                <div className="servicesunder-images">
                    <img src={pic22} alt="website" loading="lazy" />
                    <img src={pic15} alt="Server Installation" loading="lazy" />
                </div>
            </section>

            <section className="servicesunder">
                <div className="servicesunder-content">
                    <h2>Office Setup</h2>
                    <p>
                        We specialize in creating efficient and productive office setups tailored to your
                        business needs. From workstations to network configurations, we provide complete
                        solutions that streamline operations and enhance workflow.
                    </p>
                </div>
                <div className="servicesunder-images">
                    <img src={pic7} alt="Office phone" loading="lazy" />
                    <img src={pic12} alt="Server Installation" loading="lazy" />
                    <img src={pic6} alt="Server Support" loading="lazy" />
                </div>
            </section>

            <section className="servicesunder">
                <div className="servicesunder-content">
                    <h2>Computer & Laptop Repair Services You Can Trust</h2>
                    <p>
                        At CompuServ, we pride ourselves on providing honest and reliable
                        repair services for your computers and laptops. We start with free diagnostics,
                        giving you a clear understanding of the issue at no cost. Rest assured,
                        we won't proceed with any repairs without your approval you'll always be
                        informed and in control of the process. Plus, for your peace of mind,
                        we offer a warranty on all our repairs, ensuring the quality of our work and your
                        satisfaction.
                        Experience repair services that put your needs first!
                    </p>
                </div>
                <div className="servicesunder-images">
                    <img src={pic11} alt="Office phone" loading="lazy" />
                    <img src={pic14} alt="Server Installation" loading="lazy" />
                    <img src={pic3} alt="Server Support" loading="lazy" />
                </div>
            </section>

            <section class="buffer-section"></section>



            <div className="background-section">
                <div className="overlay-content">
                    <h3>Contact Us</h3>
                    <p>Have Questions? Not sure about your issue? Give us a call or visit our office.</p>
                </div>
            </div>

            <section className="contact-section">
                <div className="contact-container">
                    <div className="contact-us">
                        <div className="contact-info">
                            <div className="contact-item">
                                <h3 className="contact-heading">Address:</h3>
                                <p className="contact-text">653 Main St, El Centro, CA 92243</p>
                                <p className="contact-text">Office Hours M-F 8am-5pm</p>
                            </div>
                            <div className="contact-item">
                                <h3 className="contact-heading">Phone:</h3>
                                <p className="contact-text">(760) 353-0908</p>
                            </div>
                            <div className="contact-item">
                                <h3 className="contact-heading">Email:</h3>
                                <a href="mailto:jl@compuserv-iv.com" className="contact-text">jl@compuserv-iv.com</a>
                            </div>
                        </div>
                        <div className="map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6707.971578378906!2d-115.56078672358733!3d32.79263607365716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d7672fd8e7e38d%3A0x523f9de258cc2573!2s653%20Main%20St%2C%20El%20Centro%2C%20CA%2092243!5e0!3m2!1sen!2sus!4v1730158211469!5m2!1sen!2sus"
                                width="400"
                                height="300"
                                style={{ border: 0 }}
                                allowFullScreen
                                loading="lazy"
                                title="Google Maps"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="App-footer">
                <div className="footer-content">
                    <p>Copyright &copy; 2024 CompuServ. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
}

export default App;
